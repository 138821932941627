import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import Layout from '../components/layout/Layout'
import AnchorSlug from '../components/AnchorSlug'
import ProductDetails from '../components/ProductDetails'
import TagLabel from '../components/TagLabel'
import Image from '../components/Image'

import PageData from '../data/webportal-data.yaml'

const Webportal = () => (
  <Layout>
    <section id="webportal-menu">
      <div className="container mx-auto p-8 pt-16 text-center">
        <h1 className="text-primary-500 font-semibold tracking-wide uppercase">Products & Services</h1>
        <h1 className="md:text-4xl text-3xl font-semibold tracking-tight justify-center mt-2">{PageData.title}</h1>
        <p className="md:text-lg font-light my-4">{PageData.overview}</p>
        <div className="container grid md:grid-cols-3 grid-cols-1 mx-auto text-center mt-8">
          <div className="grid-cols">
            <AnchorLink to={`#architecture`} title='Architecture'>
              <div className="px-6 py-2">Architecture</div>
            </AnchorLink>
          </div>
          {!PageData.modules ? null : 
            PageData.modules.map((module, i) => {
              return (
                <div className="grid-cols flex">
                  <AnchorLink to={`#${module.id}`} title={`${module.menu}`} className="m-auto">
                    <div className="px-6 py-2">{module.menu}</div>
                  </AnchorLink>
                </div>
              )
          })}
        </div>
      </div>
    </section>

    <section id="architecture" className="my-10 md:py-10 py-10 md:px-0 px-4 bg-gray-100 border border-grey shadow-inner">
      <div id="architecture" className="container md:flex md:p-0 p-4 mx-auto">
        <div className="md:w-full flex flex-col md:p-8 pt-2 text-center rounded-xl overflow-hidden">
          <AnchorSlug to="architecture" title="ViziRail Web Gateway Architecture" text="ViziRail Web Gateway Architecture" className='mx-auto'/>
          <p className="text-md font-light">
            The ViziRail Web Gateway Architecture is designed to be flexible in order to solve
            multiple solutions.
          </p>
          <div className="w-full md:h-full rounded-lg p-0 mt-12 overflow-hidden detail-image">
            <Image
              src="vizirail_web_operations_overview.png"
              alt="Architecture"
              className={`h-full w-full object-cover border border-gray-300 rounded-xl shadow-xl`}
            />
          </div>
        </div>
      </div>
    </section>

    {!PageData.modules
      ? null
      : PageData.modules.map((module, i) => {
          return (
            <ProductDetails
              id={module.id}
              image={module.image}
              title={module.title}
              reverse={!(i % 2)}
            >
              {!module.paragraphs ? null : 
                module.paragraphs.map(paragraph => {
                  return <p>{paragraph}</p>
              })}
              {!module.tags ? null : (
                <div className="pb-2">
                  {module.tags.map((feature) => {
                    return <TagLabel children={feature} />
                  })}     
                </div>
              )}
            </ProductDetails>
          )
        })}

    <section id="contact-us" className="md:px-0 px-4 bg-transparent">
      <div className="container flex mx-auto">
        <span className="text-lg space-x-2 mx-auto">
          <FontAwesomeIcon icon={faCaretUp} />
          <Link to="/contact">Enquire today for more information.</Link>
          <FontAwesomeIcon icon={faCaretUp} />
        </span>
      </div>
    </section>
  </Layout>
)
export default Webportal